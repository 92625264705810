import React from 'react'
import tw from 'twin.macro'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import useLocalStorage from 'react-localstorage-hook'
import { navigate } from 'gatsby'
import usePrograms from '../../hooks/usePrograms'
import attendanceData from '../../data/attendanceDates'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import { Container } from '../../components/styles'
import {
  FormHeader,
  FormContainer,
  FormFooter,
  FormSection,
  FormControl,
  InputError,
  label,
  Select,
} from '../../components/FormControls'

import Button from '../../components/Button'
import AttendanceChoice from '../../components/AttendanceChoice'
import FormikErrorFocus from '../../components/FormikErrorFocus'
import { isInfant, isPreschool, isSchoolAge } from '../../services/childService'
import { validDates } from '../../utils'
import { attendanceInitialValues, attendanceValidationSchema } from '../../forms'
import DaySelection from './daySelection/DaySelection'
import WeekSelection from './weekSelection/WeekSelection'

// Format the attendance data
const attendanceDates = attendanceData()

const getPageTitle = ageGroup => `Step 3 - ${ageGroup} Attendance`

const AttendancePage = () => {
  const [child, setChild] = useLocalStorage('child')
  const { programs } = usePrograms()

  const handleSubmit = async values => {
    console.log('Form Submitting', values)
    setChild({ ...child, attendance: values })
    navigate('/app/review/')
  }

  // Holds the current program object
  const selectedProgram = programs.find(p => p.name === child.program)

  return (
    <Layout stepType="private">
      <SEO title={getPageTitle(child.ageGroup)} />
      <Container>
        <Formik
          enableReinitialize
          initialValues={attendanceInitialValues(child, selectedProgram)}
          validationSchema={attendanceValidationSchema(child, selectedProgram)}
          onSubmit={handleSubmit}
        >
          {/* eslint-disable-next-line arrow-body-style */}
          {formikProps => {
            // console.groupCollapsed('ATTENDANCE FORMIK')
            // console.log('ERRORS', formikProps.errors)
            // console.log('VALUES', formikProps.values)
            // console.groupEnd()
            return (
              <Form name="2020-Signup" noValidate autoComplete="off">
                <FormHeader>
                  <h2>
                    {getPageTitle(
                      child.ageGroup === 'regular-programming' ? child.ageGroup : selectedProgram.displayName
                    )}
                  </h2>
                  {isInfant(child.ageGroup) && (
                    <p tw="font-bold">
                      There are no part time attendance options for Infant programs. <br />
                      Care will be requested for Monday through Friday.
                    </p>
                  )}
                  {!isInfant(child.ageGroup) && child.program === 'regular-programming' && (
                    <p>Please select the week you need care to begin and then indicate your weekly schedule.</p>
                  )}
                </FormHeader>

                {/* Start week input */}
                <FormContainer>
                  {!isInfant(child.ageGroup) && (
                    <FormSection>
                      <h3 tw="text-purple font-bold mb-4 block text-lg">Choose the days you need care</h3>
                      <p>
                        Please indicate the days you require care for{' '}
                        <strong>{`${child.firstName} ${child.lastName}`}</strong> below.
                      </p>
                      {selectedProgram.ui === 'startWeek' && (
                        <FormControl tw="w-auto flex flex-col md:flex-row items-center h-full md:space-x-4 space-y-2">
                          <AttendanceChoice
                            label={isPreschool(child.ageGroup) ? 'Full Day Care' : 'Before School'}
                            name={isPreschool(child.ageGroup) ? 'fullday' : 'before'}
                            values={
                              isPreschool(child.ageGroup) ? formikProps.values.fullday : formikProps.values.before
                            }
                            onChange={formikProps.setFieldValue}
                            showPartTime
                          />
                          {isSchoolAge(child.ageGroup) && (
                            <AttendanceChoice
                              label="After School"
                              name="after"
                              values={formikProps.values.after}
                              onChange={formikProps.setFieldValue}
                              showPartTime
                            />
                          )}
                        </FormControl>
                      )}
                      {selectedProgram.ui === 'day' && <DaySelection dates={validDates(selectedProgram.days)} />}
                      {selectedProgram.ui === 'week' && (
                        <WeekSelection dates={validDates(selectedProgram.weeks)} formikProps={formikProps} />
                      )}
                      {formikProps.errors.atLeastOne && (
                        <InputError tw="w-full p-4 text-center">{formikProps.errors.atLeastOne}</InputError>
                      )}
                    </FormSection>
                  )}
                </FormContainer>

                <FormFooter>
                  <Button to="/app/facility-choice/" isClear tw="mr-2">
                    Previous Step
                  </Button>

                  <Button isPrimary type="submit">
                    Next Step
                  </Button>
                </FormFooter>
                <FormikErrorFocus block="start" />
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Layout>
  )
}

export default AttendancePage

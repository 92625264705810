import React from 'react'
import tw from 'twin.macro'
import { AiFillWarning } from 'react-icons/ai'
import { parseISO, format, nextFriday } from 'date-fns'
import { ErrorMessage } from 'formik'

import differenceInQuarters from 'date-fns/esm/fp/differenceInQuarters/index.js'
import { FormContainer, FormControl, InputError, Row, styledCheckbox } from '../../../components/FormControls'
import WeekAttendanceChoice from './WeekAttendanceChoice'

function formatDate(date) {
  return `${format(parseISO(date), 'MMM d')} – ${format(nextFriday(parseISO(date)), 'MMM d, yyyy')}`
}

const WeekSelection = ({ dates, formikProps, ...props }) => {
  // console.log('WEEK SELECTION DATES', dates, formikProps)
  if (dates.length)
    return (
      <div tw="mt-6 divide-y divide-gray-200" {...props}>
        {dates.map((date, i) => (
          <Row key={i} tw="flex sm:flex-wrap">
            <div tw="w-full md:w-1/3 text-purple bg-gray-100">
              <FormControl tw="m-4 md:m-6 text-center md:text-left text-sm md:whitespace-nowrap">
                <strong>Week of:</strong> <span tw="whitespace-nowrap block">{formatDate(date)}</span>
                {formikProps.errors[`${date}_atLeastOnePartTime`] && (
                  <InputError tw="pt-4 text-left whitespace-normal">
                    {formikProps.errors[`${date}_atLeastOnePartTime`]}
                  </InputError>
                )}
              </FormControl>
            </div>

            <div tw="w-full md:w-2/3">
              <FormControl tw="w-auto flex flex-col md:flex-row items-center h-full md:ml-6 my-0">
                <WeekAttendanceChoice
                  name={date}
                  values={formikProps.values[date]}
                  onChange={formikProps.setFieldValue}
                  formikProps={formikProps}
                ></WeekAttendanceChoice>
              </FormControl>
            </div>
          </Row>
        ))}
      </div>
    )

  return (
    <div tw="mt-6" {...props}>
      <AiFillWarning tw="inline-block text-purple" /> We're sorry - there are no weeks to select at this time.
    </div>
  )
}

export default WeekSelection

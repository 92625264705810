import React from 'react'
import useLocalStorage from 'react-localstorage-hook'
import { Link, navigate } from 'gatsby'
import tw, { styled, css } from 'twin.macro'
import { parseISO, format, differenceInYears, nextFriday } from 'date-fns'
import { FaBirthdayCake, FaEnvelope, FaPhone } from 'react-icons/fa'
import { useAuth } from '../context/auth-provider'
import useFetch from '../hooks/useFetch'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import { FormHeader, FormContainer, FormFooter } from '../components/FormControls'
import Button from '../components/Button'
import { isSchoolAge, isPreschool } from '../services/childService'
import usePrograms from '../hooks/usePrograms'
import useBrowserStorage from '../hooks/useBrowserStorage'

const Column = styled.div`
  ${tw`w-full sm:w-1/2 md:w-1/3 p-2 md:p-6`}
`

const row = css`
  ${tw`block p-4 md:p-0 flex flex-col md:flex-row items-center transform transition duration-150 hocus:bg-gray-100 hocus:cursor-pointer`}
`

const pageTitle = `Step 4 — Review and Submit`

const formatWeekDates = date =>
  `${format(parseISO(date), 'MMM d')} – ${format(nextFriday(parseISO(date)), 'MMM d, yyyy')}`

const days = { Mo: 1, Tu: 2, We: 3, Th: 4, Fr: 5 }

const removeFalsy = obj => {
  const newObj = {}
  Object.keys(obj).forEach(prop => {
    if (obj[prop]) {
      newObj[prop] = obj[prop]
    }
  })
  return newObj
}

const weeksWithDaysSelected = obj => {
  const newObj = {}
  Object.entries(obj).forEach(week => {
    // Check if any of the days are set to true
    if (Object.values(week[1]).filter(day => day).length) {
      newObj[`${week[0]}`] = { ...week[1] }
    }
  })
  return newObj
}

const sortDaysOfWeek = obj => Object.keys(obj).sort((a, b) => days[a] - days[b])

const formatPreschoolAttendance = fullday => {
  const sorted = sortDaysOfWeek(fullday)
  return (
    <>
      <p>
        <strong>Full day care on:</strong>
        <br /> {sorted.join(', ')}
      </p>
    </>
  )
}

const formatSchoolageAttendance = (before, after) => {
  const sortedBefore = sortDaysOfWeek(before)
  const sortedAfter = sortDaysOfWeek(after)

  return (
    <>
      {!!sortedBefore.length && (
        <span tw="pr-8">
          <strong>Before school:</strong>
          <br /> {sortedBefore.join(', ')}
        </span>
      )}
      {!!sortedAfter.length && (
        <span>
          <strong>After school:</strong>
          <br /> {sortedAfter.join(', ')}
        </span>
      )}
    </>
  )
}

const ReviewPage = () => {
  const { user } = useAuth()
  const [child] = useLocalStorage('child')
  const [facilities] = useBrowserStorage('sessionStorage', 'facilities')
  const { programs } = usePrograms()
  const { setFetchOptions, isLoading } = useFetch()

  if (!child || !user) return null

  const successCallback = ({ data }) => {
    // pull the facility info by first choice
    const chosenFacility = facilities.find(f => f.name === child.firstFacilityChoice)
    navigate('/app/thank-you/', { facility: chosenFacility })
  }

  const handleSubmit = async () => {
    const body = { ...{ guardian: user.id }, ...child }
    console.log('Form Submitting', body)
    setFetchOptions({
      method: 'POST',
      endpoint: '/.netlify/functions/create-enrollment',
      body,
      successCallback,
    })
  }

  const age = differenceInYears(new Date(), parseISO(child.birthday.substring(0, 10)))

  // Holds the current program object
  const selectedProgram = programs.find(p => p.name === child.program)

  return (
    <Layout stepType="private">
      <SEO title={pageTitle} />
      <Container>
        <FormHeader>
          <h2>{pageTitle}</h2>
          <p>Before submitting, please review the information below to ensure that it is correct.</p>
        </FormHeader>

        <FormContainer>
          <div css={row}>
            <Column>
              <h3 tw="text-purple font-bold">Guardian/Parent</h3>
            </Column>
            <Column tw="w-full sm:w-1/2 md:w-1/3 md:p-6 leading-tight text-sm">
              <strong>{`${user.firstName} ${user.lastName}`}</strong>
              <br />
              {user.address.formattedAddress}
            </Column>
            <Column tw="w-full sm:w-1/2 md:w-1/3 md:p-6 leading-tight text-sm">
              <FaEnvelope tw="inline-block mr-2" size={12} /> {user.email} <br />
              <FaPhone tw="inline-block mr-2" size={12} /> {user.phone} <br />
              <FaBirthdayCake tw="inline-block mr-2" size={12} />{' '}
              {format(parseISO(user.birthday.substring(0, 10)), 'MMMM d, yyyy')}
            </Column>
          </div>

          <div css={row}>
            <Column>
              <h3 tw="text-purple font-bold">Child Information</h3>
            </Column>
            <Column tw="w-full sm:w-1/2 md:w-1/3 md:p-6 leading-tight text-sm">
              <strong>{`${child.firstName} ${child.lastName}`}</strong>
              <br />
              {child.ageGroup}
            </Column>
            <Column tw="w-full sm:w-1/2 md:w-1/3 md:p-6 leading-tight text-sm">
              <FaBirthdayCake tw="inline-block mr-2" size={12} />{' '}
              {format(parseISO(child.birthday.substring(0, 10)), 'MMMM d, yyyy')}
            </Column>
          </div>

          <Link to="/app/facility-choice/" css={row}>
            <Column>
              <h3 tw="text-purple font-bold">Facility Choice</h3>
            </Column>
            <Column tw="w-full sm:w-1/2 md:w-1/3 md:p-6 leading-tight text-sm">
              First Choice <br />
              <strong>{child.firstFacilityChoice}</strong>
            </Column>

            <Column tw="w-full sm:w-1/2 md:w-1/3 md:p-6 leading-tight text-sm">
              Second Choice
              <br />
              <strong>{child.secondFacilityChoice}</strong>
            </Column>
          </Link>

          <Link to="/app/attendance/" css={row}>
            <Column>
              <h3 tw="text-purple font-bold">Attendance</h3>
            </Column>
            <Column tw="w-full sm:w-1/2 md:w-2/3 md:p-6 leading-tight text-sm">
              {selectedProgram.ui === 'startWeek' && (
                <div tw="flex items-center space-x-2">
                  <span tw="pr-8">
                    <strong>Start Week</strong> <br />
                    {format(parseISO(child.attendance.startWeek), 'MMMM d, yyyy')}
                  </span>
                  {selectedProgram.ui === 'startWeek' &&
                    isPreschool(child.ageGroup) &&
                    formatPreschoolAttendance(removeFalsy(child.attendance.fullday))}
                  {selectedProgram.ui === 'startWeek' &&
                    isSchoolAge(child.ageGroup) &&
                    formatSchoolageAttendance(
                      removeFalsy(child.attendance.before),
                      removeFalsy(child.attendance.after)
                    )}
                </div>
              )}
              {selectedProgram.ui === 'day' && (
                <>
                  {Object.keys(removeFalsy(child.attendance)).map((day, dID) => (
                    <span tw="block" key={dID}>
                      {format(parseISO(day), 'MMMM d, yyyy')}{' '}
                    </span>
                  ))}
                </>
              )}
              {selectedProgram.ui === 'week' && (
                <>
                  {Object.entries(weeksWithDaysSelected(child.attendance)).map((weeks, wID) => (
                    <div tw="flex items-center space-x-1 mb-2" key={wID}>
                      <span tw="pr-4">{formatWeekDates(weeks[0])}</span>
                      {Object.entries(weeks[1]).map((dayOfWeek, dowID) => (
                        <React.Fragment key={dowID}>
                          {dayOfWeek[1] && (
                            <span tw="bg-purple text-white text-xs rounded-full px-2 py-1 capitalize w-8 text-center">
                              {dayOfWeek[0]}
                            </span>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </Column>
          </Link>
        </FormContainer>

        <FormFooter>
          <Button to="/app/attendance/" isClear tw="mr-2">
            Previous Step
          </Button>

          <Button isPrimary type="submit" onClick={handleSubmit} isLoading={isLoading}>
            Submit
          </Button>
        </FormFooter>
      </Container>
    </Layout>
  )
}

export default ReviewPage

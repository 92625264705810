import React, { useRef } from 'react'
import tw from 'twin.macro'
import useLocalStorage from 'react-localstorage-hook'
import useBrowserStorage from '../hooks/useBrowserStorage'
import useFacilities from '../hooks/useFacilities'

// import useLocalStorage from 'react-localstorage-hook'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import { FormHeader, FormFooter } from '../components/FormControls'
import Button from '../components/Button'

const pageTitle = `Thank you`

const ThankYouPage = props => {
  const [child] = useLocalStorage('child') // Move to provider or jsut call localStorage directly
  const [facilities] = useBrowserStorage('sessionStorage', 'facilities')
  const facility = useRef(facilities.find(fac => fac.name === child.firstFacilityChoice))

  return (
    <Layout hideSteps>
      <SEO title={pageTitle} />
      <Container>
        <FormHeader tw="text-center">
          <h1 tw="text-2xl md:text-4xl font-bold text-purple mb-4 text-center">{pageTitle}</h1>
          <p>
            Your child care requirements for <strong>{`${child.firstName} ${child.lastName}`}</strong> have been
            submitted.
          </p>
          <p>
            <strong>
              Your child's site supervisor/program leader will contact you to confirm your child care requirements.{' '}
              {child.attendedBeforeClosure === 'false' && (
                <span>
                  It may take longer to receive confirmation if your child is starting our programs for the first time
                  this Fall as we will be processing all returning children first.
                </span>
              )}
            </strong>
          </p>
          <p>
            Please feel free to contact <a href={`mailto:${facility.current.email}`}>{facility.current.email}</a> or{' '}
            <a href="mailto:info@umbrellafamily.com">info@umbrellafamily.com</a> if you have any questions.
          </p>
          <p>
            If you would like to submit child care requirements for an additional child, click the button below.
            Otherwise you can close this browser window.
          </p>
        </FormHeader>
        <FormFooter tw="sm:justify-center">
          <Button to="/app/dashboard" isPrimary>
            Add another child
          </Button>
        </FormFooter>
      </Container>
    </Layout>
  )
}

export default ThankYouPage

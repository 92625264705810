import React, { useState, useRef, useEffect } from 'react'
import tw, { styled, css } from 'twin.macro'

import { styledCheckbox, label } from './FormControls'

const AttendanceChoice = ({ label, name, onChange, values, ...props }) => {
  const [selection, setSelection] = useState() // We store the element ID here
  const [daySelection, setDaySelection] = useState({ Mo: '', Tu: '', We: '', Th: '', Fr: '' })
  const firstUpdate = useRef(true)

  // The values are used for sorting
  const days = { Mo: 1, Tu: 2, We: 3, Th: 4, Fr: 5 }

  useEffect(() => {
    if (!firstUpdate.current) return
    firstUpdate.current = false

    if (values) {
      setDaySelection({ ...daySelection, ...values })
    }
  }, [daySelection, values])

  /**
   * Takes the daySelection state and decides what to do with changes
   * Called on each click of a part time day checkbox
   */
  const handleDayClick = ({ target }) => {
    const newState = {}

    if (daySelection[target.name]) {
      newState[target.name] = false
    } else {
      newState[target.name] = true
    }
    setDaySelection({ ...daySelection, ...newState })

    // Pass change up
    onChange(`${name}.${target.name}`, target.checked)
  }

  return (
    <>
      <div tw="flex flex-col md:flex-row mb-4 md:my-0 w-full justify-center">
        <div tw="flex p-3 text-sm font-semibold text-purple bg-gray-100 items-center justify-center">
          <span>{label}</span>
        </div>
        <div tw="flex m-auto md:ml-2">
          {Object.keys(days).map((day, i) => (
            <div tw="flex flex-col items-center" key={i}>
              <label tw="text-xs font-bold py-2 px-3 border-b border-purple-lightest" htmlFor={day}>
                {day}
              </label>
              <input
                type="checkbox"
                id={day}
                name={day}
                onChange={handleDayClick}
                css={styledCheckbox}
                checked={daySelection[day]}
                tw="my-2"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AttendanceChoice

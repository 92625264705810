import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
// import Img from 'gatsby-image' // to take image data and render it
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import tw, { styled, css } from 'twin.macro'
import { lightFormat } from 'date-fns'
import useLocalStorage from 'react-localstorage-hook'
import attendanceData from '../data/attendanceDates'
import ageGroupsData from '../data/ageGroups' // DELETE ME
import * as childService from '../services/childService'
import useProgramImages from '../hooks/useProgramImages'
import FormikErrorFocus from '../components/FormikErrorFocus'

// Form Validation
import { enrollInitialValues, enrollValidationSchema } from '../forms'

// Styling

// Hooks
import { useAuth } from '../context/auth-provider'
import usePrograms from '../hooks/usePrograms'

// Components
import SEO from '../components/SEO'
import { Container, ProgramCard } from '../components/styles'
import Layout from '../components/Layout'
import {
  FormControl,
  Row,
  InputError,
  input,
  Select,
  label,
  FormHeader,
  FormContainer,
  FormSection,
  FormFooter,
} from '../components/FormControls'
import Button from '../components/Button'

const RequestPage = ({ programName, ...props }) => {
  const { user } = useAuth()
  const [child, setChild] = useLocalStorage('child', {})

  const { programs, isLoading } = usePrograms()
  const [ageGroup, setAgeGroup] = useState(() => childService.pickAgeGroup(child.ageGroup))

  // Below is dumb - fix it, clean it up, what ever.
  // It's used to get the current programs allowed age groups from the ageGroupsData and populate the Program age group
  // This data should come from the get-programs api endpoint
  const programAgeGroups = useRef(() =>
    programs.find(p => p.name === programName)?.ageGroups.map(ag => ageGroupsData.find(f => f.name === ag))
  )

  const startWeeks = useRef(() => attendanceData())

  const [programImages] = useState(useProgramImages())

  let programImage

  // Set the program image if an age group has been selected
  useEffect(() => {
    if (!ageGroup) return
    programImage = getImage(childService.pickAgeGroupImage(programImages, ageGroup))
  }, [programImages, ageGroup])

  // Do we already have a child stored?
  const getInitialValues = progName => {
    let initialValues = enrollInitialValues(progName)

    // add the programTypes that the current program accepts
    initialValues.programTypes = programs.find(p => p.name === progName)?.programTypes

    // enrollInitialValues.program = programName
    if (Object.entries(child).length !== 0) {
      initialValues = { ...initialValues, ...child }
    }
    return initialValues
  }

  const handleChildSelection =
    ({ handleChange, setFieldValue }) =>
    e => {
      const { firstName, lastName, birthday } = user.children.find(child => child._id === e.target.value)
      setFieldValue('firstName', firstName)
      setFieldValue('lastName', lastName)
      setFieldValue('birthday', birthday)
      // Hook back into Formik
      handleChange(e)
    }

  const handleAgeGroupTypeSelection =
    ({ handleChange, setFieldValue, values }) =>
    e => {
      // Update state if different
      const program = childService.pickAgeGroup(e.target.value)

      if (programName === 'regular-programming') {
        // Clear any attendance that's been previously set. Needed in case we're switching from/to school age care but keep the start date
        const { startWeek } = values.attendance
        setFieldValue('attendance', { startWeek })
      }

      if (program && program.name !== ageGroup?.name) {
        setAgeGroup(program)
      }

      // Hook back into Formik
      handleChange(e)
    }

  const handleStartWeekSelection =
    ({ handleChange }) =>
    e => {
      console.log('Start Week Changed')
      handleChange(e)
    }

  /**
   * Event handler submit
   *
   */
  const handleSubmit = async values => {
    console.log('Form Submitting', values)

    setChild(values)
    navigate('/app/facility-choice/')
  }

  const pageTitle = `Request Care for ${programs?.find(program => program.name === programName)?.displayName}`

  return (
    <Layout stepType="private">
      <SEO title={pageTitle} />
      <Container>
        <Formik
          enableReinitialize
          initialValues={getInitialValues(programName)}
          validationSchema={enrollValidationSchema(programName)}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <Form name="childInformation" noValidate autoComplete="off">
              <Field type="hidden" name="program" />
              <FormHeader>
                <h2>{pageTitle}</h2>
                <p>Please choose the child and the age group you are requesting care for</p>
              </FormHeader>

              <FormContainer>
                <FormSection>
                  <Row tw="flex">
                    <FormControl tw="w-full">
                      <label css={label} htmlFor="child">
                        Choose a child to register
                      </label>
                      <Field id="child" name="child" as={Select} onChange={handleChildSelection(formikProps)} required>
                        <option value="" default>
                          Please Select
                        </option>
                        {user.children.map((child, cid) => (
                          <option
                            key={cid}
                            value={child._id}
                            disabled={child.enrollments.find(e => e.program === programName)}
                          >
                            {`${child.firstName} ${child.lastName} ${
                              child.enrollments.find(e => e.program === programName) ? '- Already requested' : ''
                            }`}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage name="child" component={InputError} />
                    </FormControl>
                    <Field type="hidden" name="firstName" />
                    <Field type="hidden" name="lastName" />
                    <Field type="hidden" name="birthday" />
                  </Row>
                </FormSection>

                <FormSection tw="md:flex flex-wrap items-center bg-gray-100">
                  <div tw="w-full sm:w-1/2 md:w-1/3">
                    <Row tw="flex">
                      <FormControl tw="w-full">
                        <label css={label} htmlFor="ageGroup">
                          Program Age Group
                        </label>
                        <Field
                          id="ageGroup"
                          name="ageGroup"
                          as={Select}
                          onChange={handleAgeGroupTypeSelection(formikProps)}
                          required
                        >
                          <option value="" default>
                            Please Select
                          </option>
                          {programAgeGroups.current().map(ag => (
                            <option key={ag.name} value={ag.name}>
                              {ag.displayName}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="ageGroup" component={InputError} />
                      </FormControl>
                      <Field type="hidden" name="programTypes" />
                    </Row>
                  </div>
                  {ageGroup && (
                    <>
                      <div tw="hidden md:block w-1/3 px-8">
                        {programImage && (
                          <GatsbyImage
                            tw="rounded-full shadow-lg border border-gray-100"
                            image={programImage}
                            alt={ageGroup.displayName}
                          />
                        )}
                      </div>
                      <ProgramCard>
                        <h3 tw="text-lg font-bold">{ageGroup.displayName}</h3>
                        <p>{childService.formatAgeRange(ageGroup)}</p>
                      </ProgramCard>
                    </>
                  )}
                </FormSection>
                {programName === 'regular-programming' ? (
                  <FormSection>
                    <FormControl>
                      <label css={label} htmlFor="startWeek">
                        Starting week
                      </label>
                      <div tw="flex flex-col">
                        <Field
                          as={Select}
                          id="startWeek"
                          name="attendance.startWeek"
                          onChange={handleStartWeekSelection(formikProps)}
                          required
                        >
                          <option value="" default>
                            Please Select
                          </option>
                          {startWeeks.current().map((date, i) => (
                            <option key={i} value={date.name}>
                              {date.label}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name="attendance.startWeek" component={InputError} />
                      </div>
                    </FormControl>
                  </FormSection>
                ) : (
                  <input type="hidden" name="attendance.startWeek" value={lightFormat(new Date(), 'yyyy-MM-dd')} />
                )}
              </FormContainer>

              <FormFooter>
                <Button to="/app/dashboard/" onClick={() => localStorage.removeItem('child')} isClear tw="mr-2">
                  Cancel
                </Button>

                <Button isPrimary type="submit">
                  Next Step
                </Button>
              </FormFooter>
              <FormikErrorFocus />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default RequestPage

import React, { useState, useEffect, useRef } from 'react'
import tw from 'twin.macro'

// Custom Hooks
import { navigate, Link } from 'gatsby'
import { useAuth } from '../context/auth-provider'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import { FormHeader } from '../components/FormControls'
import useFetch from '../hooks/useFetch'
import Loader from '../images/oval.svg'

const pageTitle = `Confirming, please wait`

const LoginPage = ({ location }) => {
  const { login } = useAuth()
  const firstUpdate = useRef(true)

  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(true)
  // const { setFetchOptions, isLoading } = useFetch()

  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const redirect = params.get('redirect')

  useEffect(() => {
    if (!token) {
      setIsLoading(false)
      setError('Session expired')
      return
    }
    if (firstUpdate.current) {
      firstUpdate.current = false

      login(token)
        .then(res => {
          console.log(res)
          navigate('/app/dashboard/', { redirect })
        })
        .catch(err => {
          console.error(err)
          setIsLoading(false)
          setError(err.message)
        })
    }
  }, [login, redirect, token])

  return (
    <Layout hideSteps>
      <SEO title={pageTitle} />
      <Container>
        {isLoading && (
          <FormHeader>
            <h2>{pageTitle}</h2>
          </FormHeader>
        )}
        {error && (
          <FormHeader>
            <h2>Oops! {error}</h2>
            <p>
              Tokens expire within 10 minutes of issue. You'll need to go back <Link to="/">here</Link> and start again.
            </p>
            <p>
              If you continue to have problems. Please feel free to contact{' '}
              <a href="mailto:info@umbrellafamily.com">info@umbrellafamily.com</a>
            </p>
          </FormHeader>
        )}
      </Container>
    </Layout>
  )
}

export default LoginPage

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import tw from 'twin.macro'
import { Formik, Form, ErrorMessage } from 'formik'
import useLocalStorage from 'react-localstorage-hook'
import { navigate } from 'gatsby'
import { Loader as GMapsLoader } from '@googlemaps/js-api-loader'
import useGoogleDistanceMatrix from '../hooks/useGoogleDistanceMatrix'
import useFacilities from '../hooks/useFacilities'
import { useAuth } from '../context/auth-provider'
import { getLatLng, parseResults, getMapEmbed } from '../services/facilityService'
import { isSchoolAge } from '../services/childService'

import { facilityChoiceInitialValues, facilityChoiceValidationSchema } from '../forms'
import { facilityChoiceTestValues } from '../forms/testData'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import {
  FormHeader,
  FormContainer,
  FormSection,
  FormFooter,
  FormControl,
  Row,
  InputError,
  input,
  Select,
  label,
} from '../components/FormControls'
import Button from '../components/Button'
import PlacesAutocomplete from '../components/PlacesAutocomplete'
import FormikErrorFocus from '../components/FormikErrorFocus'
import FacilityDistances from '../components/FacilityDistances'

// Set the initial values for the form, fill with test values if the environment DEBUG=forms is set
let initialValues = facilityChoiceInitialValues
if (process.env.DEBUG && process.env.DEBUG.split(',').find(el => el === 'forms'))
  initialValues = { ...facilityChoiceInitialValues, ...facilityChoiceTestValues }

const pageTitle = `Step 2 — Facility Choice`

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
const handleKeyDown = keyEvent => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

// Render Page
const FacilityChoicePage = () => {
  const { user } = useAuth()
  const firstUpdate = useRef(true)
  const searchAddress = useRef({})
  const formikInstance = useRef()
  const showSecondChoice = useRef(true)
  const [child, setChild] = useLocalStorage('child')
  const { facilities } = useFacilities({
    coordinates: user.address.coordinates,
    ageGroups: child.ageGroup,
    programs: child.programTypes.join(','),
    openOn: child.attendance.startWeek,
    sort: 'distance',
  })
  const [choices, setChoices] = useState(facilities)

  /**
   * Set the initial values for the form based on what's in Local Storage
   */
  const getInitialValues = useMemo(() => {
    console.log('Setting initial values for form')

    if (Object.entries(child).length !== 0) {
      // Do we have address data because we've been to this screen before?
      if (child.searchAddress?.formattedAddress) {
        searchAddress.current = {
          formattedAddress: child.searchAddress.formattedAddress,
          coordinates: child.searchAddress.coordinates,
        }
        // Otherwise, take the users address data
      } else {
        searchAddress.current = {
          formattedAddress: user.address.formattedAddress,
          // coordinates: [user.address.coordinates.join(',')],
          coordinates: user.address.coordinates,
        }
      }

      return {
        ...initialValues,
        searchAddress: searchAddress.current,
        ...child,
      }
    }
    return initialValues
  }, [child, user.address.coordinates, user.address.formattedAddress])

  // initialized the Distance Matrix
  const {
    init: matrixInit,
    ready: matrixReady,
    distances: { loading, status, data },
    setMatrix,
  } = useGoogleDistanceMatrix({
    matrixOptions: {
      travelMode: 'DRIVING',
    },
    initOnMount: false, // Disable initializing when the component mounts
  })

  // Load Google maps for the distance matrix
  useEffect(() => {
    const loader = new GMapsLoader({
      apiKey: process.env.GATSBY_GOOGLE_API_KEY,
      libraries: ['places'],
    })

    loader.load().then(() => {
      matrixInit()
    })
  }, [matrixInit])

  useEffect(() => {
    if (facilities.length === 1) {
      console.log('Hiding second facility choice')
      showSecondChoice.current = false
      formikInstance.current.setFieldValue('secondFacilityChoice', 'N/A')
    } else {
      showSecondChoice.current = true
    }
  }, [facilities.length])

  // First RUN Check
  useEffect(() => {
    // console.log('Good for first run')
    if (!firstUpdate.current || !matrixReady || !searchAddress.current.coordinates || !facilities.length) return
    firstUpdate.current = false
    console.groupCollapsed('First RUN')
    console.log('Matrix Status', matrixReady)
    console.log('Current Search Address', searchAddress.current)
    console.log('Facilities', facilities)

    // Don't do anything unless we have what we need in LocalStorage
    if (!!user?.address?.coordinates && !!facilities.length) {
      // Don't want to mutate the original, so we make a copy before reversing lng/lat to lat/lng
      const reversedCoordinates = [...searchAddress.current.coordinates].reverse()
      const origins = [reversedCoordinates.join(',')]
      const destinations = getLatLng(facilities)
      console.log('Origins', origins)
      console.log('Destinations', destinations)

      // Google Distance matrix can only accept 25 destinations
      console.log(window.google)
      setMatrix({ origins, destinations: destinations.slice(0, 25) }, true)
      matrixInit()
    }
    console.groupEnd()
  }, [facilities, matrixReady, setMatrix, user])

  // Will Choose the first and second facility automatically as long as the user hasn't already done a custom selection
  // TODO make sure the selections go back to previous select
  const convenientlyChoose = useCallback(parsed => {
    formikInstance.current.setFieldValue('firstFacilityChoice', parsed[0].name)
    formikInstance.current.setFieldValue('secondFacilityChoice', parsed[1].name)
  }, [])

  // Distance Matrix has run
  useEffect(() => {
    if (!loading && !!status && Object.entries(data).length) {
      console.groupCollapsed('Distance Matrix has Run')
      console.log('Loading', loading)
      console.log('Status', !!status)
      console.log('data', data)
      console.log('Parsing data')

      const hack = []

      // THIS IS A HACK FIX ME
      // if (child.program === 'summer-camp-2021') {
      //   hack = facilities.filter(fac => fac.name !== 'Bellmoore' && fac.name !== 'Glen Echo')
      // } else {
      //   hack = facilities
      // }
      // const parsed = parseResults(hack, data)
      const parsed = parseResults(facilities, data)

      setChoices(parsed)
      convenientlyChoose(parsed)
      console.groupEnd()
    }
  }, [child.program, convenientlyChoose, data, facilities, loading, status])

  // The User has supplied a new autocomplete address
  const handleGeoData = formik => data => {
    console.log('Handling GEO Data', data)
    formik.setFieldValue('searchAddress.formattedAddress', data.formattedAddress)
    // Below is annoying but google returns lat/lng but mongo needs lng/lat.
    formik.setFieldValue('searchAddress.coordinates', [].concat(Object.values(data.coordinates)).reverse())
    const destinations = getLatLng(facilities)
    setMatrix(
      {
        origins: [Object.values(data.coordinates).join(',')],
        destinations: destinations.slice(0, 25),
      },
      true
    )
  }

  const handleFacilityChange =
    ({ handleChange, setFieldValue }) =>
    e => {
      console.log('Selection changed')
      handleChange(e)
    }

  const handleSubmit = async values => {
    console.log('Form Submitting', values)
    setChild({ ...child, ...values })
    navigate('/app/attendance/')
  }

  return (
    <Layout stepType="private">
      <SEO title={pageTitle} />
      <Container>
        <Formik
          enableReinitialize
          initialValues={getInitialValues}
          validationSchema={facilityChoiceValidationSchema}
          onSubmit={handleSubmit}
        >
          {/* eslint-disable-next-line arrow-body-style */}
          {formikProps => {
            formikInstance.current = formikProps
            {
              /* console.log(formikProps.initialValues, formikProps.values) */
            }
            return (
              <Form onKeyDown={handleKeyDown} name="2020-Signup" noValidate autoComplete="off">
                <FormHeader>
                  <h2>{pageTitle}</h2>
                  <p>
                    {/* Based on your address, we’ve automatically selected the closest facilities to you below.{' '} */}
                    {facilities.length > 1 && (
                      <strong>Please review and update the facility selection if necessary.</strong>
                    )}
                    {facilities.length === 1 && (
                      <strong>We currently have only one facility that offers {child.ageGroup} care.</strong>
                    )}
                  </p>
                </FormHeader>

                <FormContainer>
                  {facilities.length > 1 ? (
                    <>
                      <FormSection tw="bg-gray-100">
                        <Row>
                          <FormControl>
                            <label css={label} htmlFor="facilitiesNearAddress">
                              Pick{' '}
                              {isSchoolAge(child.ageGroup) || facilities.length === 1 ? 'a facility' : 'facilities'}{' '}
                              close to this address
                            </label>

                            <>
                              <PlacesAutocomplete
                                id="facilitiesNearAddress"
                                name="searchAddress.formattedAddress"
                                placeholder="Enter an address..."
                                value={formikProps.values.searchAddress.formattedAddress}
                                css={input}
                                // onChange={formikProps.handleChange}
                                // onBlur={formikProps.handleBlur}
                                onGeoData={data => {
                                  handleGeoData(formikProps)(data)
                                }}
                              />
                              <ErrorMessage name="searchAddress.formattedAddress" component={InputError} />
                            </>
                          </FormControl>
                        </Row>
                      </FormSection>

                      <FormSection>
                        <Row tw="flex">
                          <div tw="w-full sm:w-1/2 md:w-2/5">
                            <h3 tw="text-purple font-bold mb-6 block text-lg">
                              {showSecondChoice.current ? 'First Choice *' : 'Select Facility'}
                            </h3>
                            <FormControl tw="w-auto">
                              <FacilityDistances
                                id="firstFacilityChoice"
                                name="firstFacilityChoice"
                                value={formikProps.values.firstFacilityChoice}
                                component={Select}
                                facilities={choices}
                                onChange={handleFacilityChange(formikProps)}
                              />
                              <ErrorMessage name="firstFacilityChoice" component={InputError} />
                            </FormControl>
                          </div>

                          {!!formikProps.values.firstFacilityChoice && (
                            <div tw="w-full sm:w-1/2 md:w-3/5 sm:pl-10">
                              <iframe
                                src={getMapEmbed(facilities, formikProps.values.firstFacilityChoice)}
                                title="First"
                                width="100%"
                                height="300"
                                frameBorder="0"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                              />
                            </div>
                          )}
                        </Row>
                      </FormSection>

                      {showSecondChoice.current && (
                        <FormSection>
                          <Row tw="flex">
                            <div tw="w-full sm:w-1/2 md:w-2/5">
                              <h3 tw="text-purple font-bold mb-6 block text-lg">Second Choice *</h3>
                              <FormControl tw="w-auto">
                                <FacilityDistances
                                  id="secondFacilityChoice"
                                  name="secondFacilityChoice"
                                  value={formikProps.values.secondFacilityChoice}
                                  component={Select}
                                  facilities={choices}
                                  onChange={handleFacilityChange(formikProps)}
                                />
                                <ErrorMessage name="secondFacilityChoice" component={InputError} />
                              </FormControl>
                            </div>

                            {!!formikProps.values.secondFacilityChoice && (
                              <div tw="w-full sm:w-1/2 md:w-3/5 sm:pl-10">
                                <iframe
                                  src={getMapEmbed(facilities, formikProps.values.secondFacilityChoice)}
                                  title="second"
                                  width="100%"
                                  height="300"
                                  frameBorder="0"
                                  style={{ border: 0 }}
                                  allowFullScreen=""
                                  aria-hidden="false"
                                  tabIndex="0"
                                />
                              </div>
                            )}
                          </Row>
                        </FormSection>
                      )}
                    </>
                  ) : (
                    <FormSection>
                      <Row tw="text-center">
                        <h3 tw="text-purple font-bold mb-6 block text-lg">That's odd, no facilities were found.</h3>
                        <p>Try clicking the 'Previous Step' button below and try again.</p>
                      </Row>
                    </FormSection>
                  )}
                </FormContainer>

                <FormFooter>
                  <Button to={`/app/request/${child.program}`} isClear tw="mr-2">
                    Previous Step
                  </Button>

                  <Button isPrimary type="submit" disabled={facilities.length === 0}>
                    Next Step
                  </Button>
                </FormFooter>
                <FormikErrorFocus />
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Layout>
  )
}

export default FacilityChoicePage

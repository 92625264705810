import React from 'react'
import PropTypes from 'prop-types'

import { InputError } from './FormControls'

const FacilityDistances = ({ component: Component, facilities, defaultOption, value: selectedValue, ...props }) => {
  const renderResults = () => {
    if (!facilities) return

    const output = facilities.map((address, idx) => (
      <option key={idx} value={address.name}>
        {`${address.name} ${address?.distance?.text ? ` – ${address.distance.text}` : ''}`}
      </option>
    ))

    return output
  }

  return (
    <div>
      <Component value={selectedValue} {...props}>
        {defaultOption && (
          <option value="" default>
            {defaultOption}
          </option>
        )}
        {renderResults()}
      </Component>
      {status !== 'OK' && <InputError>{status}</InputError>}
    </div>
  )
}

FacilityDistances.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  defaultOption: PropTypes.string,
  facilities: PropTypes.array,
  className: PropTypes.string,
  onChange: PropTypes.func,
}

FacilityDistances.defaultProps = {
  value: '',
  defaultOption: 'Please Select',
  className: '',
  facilities: [],
  onChange: () => {},
}

export default FacilityDistances

import React, { useState, useEffect } from "react"
import tw from "twin.macro"
import useFetch from "../hooks/useFetch"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container } from "../components/styles"
import { FormHeader, FormContainer } from "../components/FormControls"
import Button from "../components/Button"

import { objToQueryString } from "../utils"

const pageTitle = `Confirm Care`

const ConfirmRequestPage = ({ location, ...rest }) => {
  const [view, setView] = useState("loading")

  const params = new URLSearchParams(location.search)
  const token = params.get("token")
  const program = params.get("program")
  const changeRequested = params.has("changeRequest")

  const AIRTABLE_URL =
    "https://airtable.com/embed/shrfLWbGeC15lVDt9?backgroundColor=gray"
  const API_CHANGE_REQUEST_URL = `/.netlify/functions/set-changeRequested`
  const API_CONFIRM_REQUEST_URL = `/.netlify/functions/set-confirmed`

  // Update the child record
  useEffect(() => {
    if (!token || !program) {
      setView("error")
      return
    }
    const saveData = async () => {
      const endpoint = changeRequested
        ? API_CHANGE_REQUEST_URL
        : API_CONFIRM_REQUEST_URL
      const responseFromServer = await fetchData(endpoint, token, program)
      setView(changeRequested ? "changeRequested" : "careConfirmed")
      console.log("SERVER SENT RESPONSE", responseFromServer)
    }
    saveData()
  }, [
    API_CHANGE_REQUEST_URL,
    API_CONFIRM_REQUEST_URL,
    changeRequested,
    program,
    token,
  ])

  // Save to API
  const fetchData = async (endpoint, id, program) => {
    const res = await fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id, program }),
    })
    const data = await res.json()
    return data
  }

  // Prefill the Airtable iFrame fields with query params
  function prefillFields() {
    const queryParams = {
      "prefill_Parent+Name": params.has("parentName")
        ? params.get("parentName")
        : "",
      "prefill_Child+Name": params.has("childName")
        ? params.get("childName")
        : "",
    }
    return `${AIRTABLE_URL}&${objToQueryString(queryParams)}`
  }

  return (
    <Layout hideSteps>
      <SEO title={pageTitle} />
      <Container>
        {view === "careConfirmed" ? (
          <div tw="text-center">
            <FormHeader>
              <h1>Thank you</h1>
            </FormHeader>
            <p>
              You have indicated that there is no change to this child’s
              location/age group/schedule for Fall 2022. You can expect to
              receive an email showing your Fall Fee Schedule by the end of
              June/22.
            </p>
            <p>
              If you have any questions, please email{" "}
              <a href="mailto:info@umbrellafamily.com">
                info@umbrellafamily.com
              </a>
            </p>
          </div>
        ) : null}

        {view === "changeRequested" ? (
          <div>
            <iframe
              className="airtable-embed"
              src={prefillFields()}
              frameBorder="0"
              width="100%"
              height="533"
              style={{ background: "transparent", border: "1px solid #ccc" }}
            />
          </div>
        ) : null}

        {view === "error" ? (
          <div tw="text-center">
            <FormHeader>
              <h1>Something went wrong</h1>
            </FormHeader>
          </div>
        ) : null}
      </Container>
    </Layout>
  )
}

export default ConfirmRequestPage

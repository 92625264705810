import { useState, useRef, useCallback, useEffect } from 'react'
import _debounce from 'lodash.debounce'
import useLatest from './useLatest'
import { useCountRenders } from './useCountRenders'

export const loadApiErr = '> 💡useGoogleDistanceMatrix: Google Maps API must be loaded.'

const useGoogleDistanceMatrix = ({
  matrixOptions = {},
  debounce = 300,
  googleMaps,
  cache = 24 * 60 * 60,
  callbackName,
  initOnMount = true,
}) => {
  const [ready, setReady] = useState(false)
  const [value, setVal] = useState('')
  const [distances, setDistances] = useState({
    loading: false,
    status: '',
    data: {},
  })
  const asRef = useRef(null)

  // useCountRenders('Google Distance Matrix Renders')

  // I think doing it this way prevents a render
  const googleMapsRef = useLatest(googleMaps)
  const matrixOptionsRef = useLatest(matrixOptions)

  const init = useCallback(() => {
    if (asRef.current) return

    const { google } = window
    const { current: gMaps } = googleMapsRef
    const googleApi = gMaps || google?.maps
    console.log('Initializing', google, gMaps, googleApi)

    if (!googleApi) {
      console.error('OH NO!', loadApiErr)
      return
    }

    asRef.current = new googleApi.DistanceMatrixService()
    setReady(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchDistances = useCallback(
    _debounce(({ origins, destinations }) => {
      if (!origins || !destinations || !Array.isArray(origins) || !Array.isArray(destinations) || !asRef.current) {
        setDistances({ loading: false, status: '', data: {} })
        return
      }

      console.log('Fetching new distances')
      // setDistances(prevState => ({ ...prevState, loading: true }))

      // Call Google
      asRef.current.getDistanceMatrix(
        {
          ...matrixOptionsRef.current,
          destinations,
          origins,
        },
        (data, status) => {
          // TODO
          // Need to check the data
          setDistances({ loading: false, status, data })
        }
      )
    }, debounce),
    [matrixOptionsRef]
  )

  const setMatrix = useCallback(
    (matrix = {}, shouldFetchData = true) => {
      console.log('Setting NEW Matrix', matrix)
      setVal(matrix)

      if (shouldFetchData) fetchDistances(matrix)
    },
    [fetchDistances]
  )

  useEffect(() => {
    if (!initOnMount) return () => null

    const { google } = window

    if (!googleMapsRef.current && !google?.maps && callbackName) {
      window[callbackName] = init
    } else {
      init()
    }

    return () => {
      if (window[callbackName]) delete window[callbackName]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackName, init])

  return { ready, value, distances, setMatrix, init }
}

export default useGoogleDistanceMatrix

import { useEffect, useState } from 'react'
import useFetch from './useFetch'
import { objToQueryString } from '../utils'
import useBrowserStorage from './useBrowserStorage'

/**
 * Get the programs from the API.
 *
 * @props array Array of fields to return. Ex ['name', 'address.coordinates`]
 */
const usePrograms = props => {
  const [programs, setPrograms] = useBrowserStorage('sessionStorage', 'programs', [])
  const { setFetchOptions, response, isLoading } = useFetch()

  const successCallback = res => {
    setPrograms(res.data)
  }

  // Defaults
  const params = {}

  // Only Active Programs
  if (props && 'active' in props) params.active = props.active

  const queryString = Object.keys(params).length ? `?${objToQueryString(params)}` : ''

  // If there are no programs in local storage
  useEffect(() => {
    if (programs.length > 0) return

    setFetchOptions({
      endpoint: `/.netlify/functions/get-programs/${queryString}`,
      // endpoint: `/.netlify/functions/get-programs/`,
      successCallback,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFetchOptions])

  // useEffect(() => {
  //   if (programs.length === 0) return
  // }, [programs.length])

  return { programs, setPrograms, isLoading }
}

export default usePrograms

import React, { useState, useEffect } from 'react'
// Styling
import tw, { styled, css } from 'twin.macro'

// Form Validation
import { Formik, Form, Field, ErrorMessage } from 'formik'
import useLocalStorage from 'react-localstorage-hook'
import { navigate } from 'gatsby'
import { useAuth } from '../context/auth-provider'

import FormikErrorFocus from '../components/FormikErrorFocus'

import { childInitialValues, childValidationSchema } from '../forms'
import { childTestValues } from '../forms/testData'

// Components
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container, ProgramCard } from '../components/styles'
import {
  FormControl,
  Row,
  InputError,
  input,
  Select,
  label,
  FormHeader,
  FormContainer,
  FormSection,
  FormFooter,
} from '../components/FormControls'
import Button from '../components/Button'
import useFetch from '../hooks/useFetch'

// Set the initial values for the form, fill with test values if the environment DEBUG=forms is set
let initialValues = childInitialValues
if (process.env.DEBUG && process.env.DEBUG.split(',').find(el => el === 'forms'))
  initialValues = { ...childInitialValues, ...childTestValues }

const pageTitle = `Child Information`

const successCallback = res => {
  console.log('RESULTS', res)
  navigate('/app/dashboard/')
}

const ChildInfoPage = () => {
  const { user } = useAuth()

  const [child, setChild] = useLocalStorage('child', {})
  const { setFetchOptions, isLoading } = useFetch()

  // Bail if we don't have a user
  if (!user) return null

  // Do we already have a child stored?
  const getInitialValues = () => {
    if (Object.entries(child).length !== 0) {
      return { ...initialValues, ...child }
    }
    return initialValues
  }

  /**
   * Event handler submit
   *
   */
  const handleSubmit = async body => {
    const data = { ...{ guardian: user.id }, ...body }
    console.log('Form Submitting', data)

    setFetchOptions({
      method: 'POST',
      endpoint: '/.netlify/functions/create-child',
      body: data,
      successCallback,
    })
  }

  return (
    <Layout hideSteps>
      <SEO title={pageTitle} />
      <Container>
        <Formik
          enableReinitialize
          initialValues={getInitialValues()}
          validationSchema={childValidationSchema}
          onSubmit={handleSubmit}
        >
          {formProps => (
            <Form name="childInformation" noValidate autoComplete="off">
              <FormHeader>
                <h2>{pageTitle}</h2>
                <p>Please provide the following information about your child.</p>
              </FormHeader>

              <FormContainer>
                <FormSection>
                  <Row tw="flex">
                    <FormControl tw="w-full sm:w-1/2 sm:pr-2">
                      <label css={label} htmlFor="childFirst">
                        Child's First Name
                      </label>
                      <Field id="childFirst" name="firstName" type="text" required css={input} />
                      <ErrorMessage name="firstName" component={InputError} />
                    </FormControl>

                    <FormControl tw="w-full sm:w-1/2 sm:pl-2">
                      <label css={label} htmlFor="childLast">
                        Child's Last Name
                      </label>
                      <Field id="childLast" name="lastName" type="text" css={input} required />
                      <ErrorMessage name="lastName" component={InputError} />
                    </FormControl>
                  </Row>

                  <Row tw="flex">
                    <FormControl tw="w-full">
                      <label css={label} htmlFor="childBirthday">
                        Child's Date of Birth <span tw="text-xs font-normal">(YYYY-MM-DD)</span>
                      </label>
                      <Field
                        id="childBirthday"
                        name="birthday"
                        type="date"
                        css={input}
                        placeholder="yyyy-mm-dd"
                        required
                      />
                      <ErrorMessage name="birthday" component={InputError} />
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl tw="md:w-auto">
                      <label css={label} htmlFor="childCurrentlyEnrolled">
                        Is this child currently enrolled in an Umbrella program?
                      </label>
                      <Field as={Select} id="childCurrentlyEnrolled" name="currentlyEnrolled" required>
                        <option value="" default>
                          Please Select
                        </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </Field>
                      <ErrorMessage name="currentlyEnrolled" component={InputError} />
                    </FormControl>
                  </Row>
                </FormSection>
              </FormContainer>
              <FormFooter>
                <Button to="/app/dashboard/" onClick={() => localStorage.removeItem('child')} isClear tw="mr-2">
                  Cancel
                </Button>

                <Button isPrimary type="submit">
                  Save
                </Button>
              </FormFooter>
              <FormikErrorFocus />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default ChildInfoPage

import React from 'react'
import { Router, Redirect } from '@reach/router'
import { navigate } from 'gatsby'

import LoginPage from '../pages/login'
import DashboardPage from './dashboard/dashboard'
import ChildInfoPage from './child-information'
import RequestPage from './request'
import FacilityChoicePage from './facility-choice'
import AttendancePage from './attendance/attendance'
import ReviewPage from './review'
import ThankYouPage from './thank-you'
import ConfirmRequestPage from '../pages/confirm-request'
import { useAuth } from '../context/auth-provider'
import useHasMounted from '../hooks/useHasMounted'

function PrivateRoute(props) {
  const { user } = useAuth()

  const { component: Component, location, ...rest } = props

  React.useEffect(() => {
    if (!user && location.pathname !== `/login`) {
      // If the user is not logged in, redirect to the login page.
      navigate(`/login`)
    }
  }, [location.pathname, user])
  return user ? <Component {...rest} /> : null
}

function PublicRoute(props) {
  return <div>{props.children}</div>
}

const App = () => {
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }
  return (
    <Router>
      <Redirect from="/app/children" to="/app/dashboard" />
      <PrivateRoute path="/app/dashboard" component={DashboardPage} />
      <PrivateRoute path="/app/child-information" component={ChildInfoPage} />
      <PrivateRoute path="/app/request/:programName" component={RequestPage} />
      <PrivateRoute path="/app/facility-choice" component={FacilityChoicePage} />
      <PrivateRoute path="/app/attendance" component={AttendancePage} />
      <PrivateRoute path="/app/review" component={ReviewPage} />
      <PrivateRoute path="/app/thank-you" component={ThankYouPage} />
      <PublicRoute path="/app">
        <LoginPage path="/login" />
        {/* <ConfirmRequestPage path="/confirm-request/:childId" /> */}
      </PublicRoute>

      {/* <Login path="/app/login" /> */}
    </Router>
  )
}
export default App

import { useEffect } from 'react'
import useBrowserStorage from './useBrowserStorage'
import useFetch from './useFetch'
import { objToQueryString } from '../utils'

/**
 * Get the facilities from the API.
 *
 * @props array Array of fields to return. Ex ['name', 'address.coordinates`]
 */
const useFacilities = ({
  coordinates = [-79.898776, 43.224049], // default to Umbrella head office
  ageGroups,
  programs,
  openOn,
  sort,
}) => {
  // Keep the returned data in session storage so it's available to other screens
  const [facilities, setFacilities] = useBrowserStorage('sessionStorage', 'facilities', [])
  const { setFetchOptions, response, isLoading } = useFetch()

  const successCallback = res => {
    setFacilities(res.data)
  }

  // Defaults
  const params = {}

  // Coordinates: Mongo needs Lng, Lat so we need to reverse the array
  if (coordinates) params.near = [...coordinates]

  // Age Groups
  if (typeof ageGroups === 'string') params.ageGroups = ageGroups

  // Programs
  if (typeof programs === 'string') params.programs = programs

  // Open on
  if (openOn) params.openOn = openOn

  // Open on
  if (sort) params.sort = sort

  // Build Query string
  const queryString = Object.keys(params).length ? `?${objToQueryString(params)}` : ''

  useEffect(() => {
    setFetchOptions({
      endpoint: `/.netlify/functions/get-facilities/${queryString}`,
      successCallback,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFetchOptions])

  return { facilities, setFacilities, isLoading }
}

export default useFacilities

import { useStaticQuery, graphql } from 'gatsby'

const useProgramImages = () => {
  const images = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)/" }
            relativeDirectory: { eq: "programs" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `
  )
  const [...nodes] = images?.allFile?.edges
  console.log(
    'our image query results',
    nodes.map(i => i.node)
  )
  return nodes.map(i => i.node)
  // return site.siteMetadata
}

export default useProgramImages

import React, { useEffect, useRef } from 'react'
import tw from 'twin.macro'

// Custom Hooks
import { useAuth } from '../../context/auth-provider'
import usePrograms from '../../hooks/usePrograms'
// UI
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import { Container } from '../../components/styles'
import { FormHeader, FormFooter, FormSection } from '../../components/FormControls'
import Button from '../../components/Button'
import ProgramList from './ProgramList'

const pageTitle = `Register Children`

const DashboardPage = props => {
  const { user, getUser } = useAuth()
  const firstUpdate = useRef(true)

  // This will only run once!
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      localStorage.removeItem('child')
      getUser()
    }
  }, [getUser])

  return (
    <Layout hideSteps stepType="private">
      <SEO title={pageTitle} />
      <Container>
        <FormHeader tw="text-center">
          <h1 tw="text-2xl md:text-3xl font-bold text-purple mb-2 md:mb-4">Welcome, {user.firstName}</h1>
          <p tw="font-bold">Please use this online portal to submit requests for your child care requirements.</p>
        </FormHeader>
        <FormSection tw="flex flex-wrap justify-start items-center border-t border-purple-lightest px-2 py-4 md:mb-6">
          <div tw="w-full flex flex-wrap items-center justify-center">
            {user.children && !user.children.length ? (
              <p tw="pb-0 pr-4">To begin, please add a child you would like to request care for.</p>
            ) : null}
            {user.children && user.children.length > 0 && (
              <>
                <h2 tw="w-full text-lg font-bold text-purple mb-4">Your Current Children</h2>
                <p tw="pb-4 w-full">
                  Below are the names of children you have entered.{' '}
                  <strong>
                    Please note that you still need to request care for these children in the areas below.
                  </strong>
                </p>
                <div tw="flex-auto flex flex-col md:flex-row md:space-x-2 justify-start items-start">
                  <div tw="flex flex-wrap">
                    {user.children.map(child => (
                      <span
                        key={child.id}
                        tw="py-2 px-4 my-1 mr-1 shadow-md text-purple text-sm text-center bg-white rounded-full border border-purple-lightest"
                      >
                        {child.firstName} {child.lastName}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}

            <Button tw="my-2" to="/app/child-information/" isPrimary isSmall>
              Add a child
            </Button>
          </div>
        </FormSection>

        {user.children && user.children.length > 0 && (
          <>
            <FormSection tw="border border-gray-150 bg-gray-100 p-2 py-4 rounded-lg mb-4 transition-all ease-in-out md:shadow-md hover:shadow-xl hover:border-gray-200">
              <div tw="w-full">
                <h2 tw="w-full flex-auto text-lg font-bold text-purple mb-4">
                  To Request Care for Regular Programming
                </h2>
                <p>
                  Daily full day child care for infant, toddler and preschool children. Before and after school programs
                  for kindergarten and school age children.
                </p>
                <ProgramList type="regular" />
              </div>
            </FormSection>
            <FormSection tw="border border-gray-150 bg-gray-100 p-2 rounded-lg transition-all ease-in-out md:shadow-md hover:shadow-xl hover:border-gray-200">
              <div tw="w-full pb-4 border-b border-purple">
                <h2 tw="w-full flex-auto text-lg font-bold text-purple mb-4">
                  To Request Care for Optional Programming
                </h2>
                <p>
                  Full day programs for <strong>kindergarten</strong> and <strong>school age</strong> children during
                  school breaks.
                </p>
              </div>
              <ProgramList type="optional" />
            </FormSection>
          </>
        )}
      </Container>
    </Layout>
  )
}

export default DashboardPage

import React from 'react'
import tw from 'twin.macro'
import { AiFillWarning } from 'react-icons/ai'
import { parseISO, format } from 'date-fns'
import { Field } from 'formik'

import { FormControl, styledCheckbox } from '../../../components/FormControls'

const DaySelection = ({ dates, ...props }) => {
  if (dates.length)
    return (
      <div tw="mt-6" {...props}>
        {dates.map((day, dId) => (
          <FormControl key={dId} tw="flex">
            <Field id="day" type="checkbox" name={day} css={styledCheckbox} />
            <span tw="ml-2">{format(parseISO(day), 'eeee, MMMM d, yyyy')}</span>
          </FormControl>
        ))}
      </div>
    )

  return (
    <div tw="mt-6" {...props}>
      <AiFillWarning tw="inline-block text-purple" /> We're sorry - there are no days to select at this time.
    </div>
  )
}

export default DaySelection

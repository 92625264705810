import { useRef, useEffect } from 'react'

export default val => {
  const ref = useRef(val)

  useEffect(() => {
    ref.current = val
  }, [val])

  return ref
}

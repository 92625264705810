import { format, formatISO, eachWeekOfInterval, endOfWeek, addYears } from 'date-fns'

// Not in use anymore - Jan 2021
const attendanceData = () => {
  const now = new Date()

  const formatted = dateData.reduce((acc, data) => {
    const start = parseISO(data.start)
    if (isAfter(now, start)) return acc

    const end = parseISO(data.end)

    acc.push({
      name: `${format(start, 'MMM_d_yyyy')}_${format(end, 'MMM_d_yyyy')}`,
      label: `${format(start, 'MMM. d/yyyy')} - ${format(end, 'MMM. d/yyyy')}`,
    })

    return acc
  }, [])

  return formatted
}

const newAttendanceData = () => {
  const weekData = eachWeekOfInterval(
    {
      start: new Date(),
      end: addYears(new Date(), 5),
    },
    { weekStartsOn: 1 }
  )

  const formatted = weekData.reduce((acc, monday) => {
    const friday = endOfWeek(monday)

    acc.push({
      name: formatISO(monday, { representation: 'date' }),
      // name: `${format(monday, 'MMM_d_yyyy')}_${format(friday, 'MMM_d_yyyy')}`,
      label: `${format(monday, 'MMM. d/yyyy')} - ${format(friday, 'MMM. d/yyyy')}`,
    })

    return acc
  }, [])

  return formatted
}
export default newAttendanceData

import React from 'react'
import PropTypes from 'prop-types'
import tw from 'twin.macro'

// Custom Hooks
import { useAuth } from '../../context/auth-provider'
import usePrograms from '../../hooks/usePrograms'
// UI
import Button from '../../components/Button'
import Loader from '../../images/oval.svg'

const LoaderIcon = ({ isLoading }) => {
  if (!isLoading) return null

  return (
    <span tw="text-purple">
      <Loader tw="stroke-current w-6 h-6 mt-4" />
    </span>
  )
}

const ProgramList = ({ buttonText, type }) => {
  const { user } = useAuth()
  const { programs, isLoading } = usePrograms({
    active: true, // Only need programs that are active
  })

  const reqPrograms = programs
    .filter(item => item.type.includes(type))
    .map(item => {
      item.children = []
      user.children.forEach(child => {
        if (!child.enrollments) return
        child.enrollments.forEach(enrollment => {
          if (enrollment.program === item.name) {
            item.children.push({
              firstName: child.firstName,
              lastName: child.lastName,
              id: child.id,
            })
          }
        })
      })
      return item
    })

  return (
    <div tw="space-y-8 divide-y divide-purple-lightest">
      <LoaderIcon isLoading={isLoading} />
      {!reqPrograms.length && (
        <strong tw="italic block mt-8">
          We're sorry - There are no {type} programs available to request at this time.
        </strong>
      )}
      {reqPrograms.map(program => (
        <div key={program.name} tw="flex justify-center items-center pt-4">
          <div tw="flex-auto">
            {type !== 'regular' && <h3 tw="font-bold mb-2 w-full">{program.displayName}</h3>}
            {program.children.length ? (
              <p tw="text-sm font-bold block relative pb-2 pt-2 w-full">You've requested care for...</p>
            ) : null}

            <div tw="md:flex justify-between">
              <div tw="flex flex-wrap md:pr-8 items-center">
                {program.children.length ? (
                  program.children.map(child => (
                    <span
                      key={child.id}
                      tw="py-2 px-4 my-1 mr-1 shadow-md text-white text-sm text-center bg-purple rounded-full"
                    >
                      {child.firstName} {child.lastName}
                    </span>
                  ))
                ) : (
                  <p>
                    If you would like to request care for a child, <strong>click the Request button.</strong>
                  </p>
                )}
              </div>

              <Button to={`/app/request/${program.name}`} isSecondary isSmall tw="mt-4 md:mt-0">
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

ProgramList.propTypes = {
  buttonText: PropTypes.string,
  type: PropTypes.string,
}

ProgramList.defaultProps = {
  buttonText: `Request`,
  type: 'regular',
}

export default ProgramList

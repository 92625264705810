export const getLatLng = (arr = []) => {
  if (!arr.length) return arr
  return arr.map(item => item.address.coordinates.join(','))
}

export const getMapEmbed = (facilities = [], facilityName = '') => {
  if (!facilities.length) return new Error('No facilities to get map embed from')
  const found = facilities.find(f => f.name === facilityName)

  if (found) {
    return found.mapEmbed
  }
  // return facilities.find(f => f.name === facilityName).mapEmbed
}

/**
 * Reformat Google Distance Matrix results to a more usuable structure. Remove any entries that didn't
 * return a result
 *
 * @param   {array}  facilities            [umbrella facilities from the api]
 * @param   {[type]}       destinationAddresses  [destinationAddresses description]
 * @param   {[type]}       originAddresses       [originAddresses description]
 * @param   {[type]}       rows                  [rows description]
 *
 * @return  {[]}                                 [return description]
 */
export const parseResults = (facilities = [], { destinationAddresses, originAddresses, rows }) => {
  if (!destinationAddresses || !originAddresses || !rows) {
    throw new Error(`Can't' parse matrix data`)
  }

  const withDistance = []
  const withoutDistance = []

  for (let i = 0; i < facilities.length; i++) {
    const facility = facilities[i]

    // Google Distance matrix only
    if (i <= rows[0].elements.length - 1) {
      const { distance, duration, status } = rows[0].elements[i]

      // If the distance couldn't be resolved, add it to the withoutDistance group
      if (status !== 'OK') {
        withoutDistance.push(facility)
        continue
      }

      withDistance.push({
        ...facility,
        distance,
        duration,
        status,
      })
      continue
    }

    withoutDistance.push(facility)
  }

  // const parsed = facilities.reduce((acc, cur, i) => {
  //   const compiled = { ...cur } // keep all the original data

  //   // Google Distance matrix only
  //   if (i <= rows[0].elements.length - 1) {
  //     const { distance, duration, status } = rows[0].elements[i]

  //     // If the distance couldn't be resolved, move on
  //     if (status === 'OK') {
  //       compiled.distance = distance
  //       compiled.duration = duration
  //       compiled.status = status
  //     }
  //   }

  //   acc.push(compiled)
  //   return acc
  // }, [])
  // debugger
  return [
    ...withDistance.sort((a, b) => a.distance.value - b.distance.value),
    ...withoutDistance.sort((a, b) => a.name - b.name),
  ]
  // return parsed.sort((a, b) => a.distance.value - b.distance.value)
}

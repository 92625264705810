import React, { useState, useRef, useEffect } from "react"
import tw, { styled, css } from "twin.macro"

import { styledCheckbox, label } from "../../../components/FormControls"

const WeekAttendanceChoice = ({
  name,
  onChange,
  values,
  formikProps,
  ...props
}) => {
  const [selection, setSelection] = useState() // We store the element ID here
  const fullTimeCheck = useRef(null)
  const partTimeCheck = useRef(null)
  const firstUpdate = useRef(true)

  // The values are used for sorting
  const days = { mo: 1, tu: 2, we: 3, th: 4, fr: 5 }

  useEffect(() => {
    // if (!firstUpdate.current) return
    // console.log('FORMIK VALUES FOR WEEK SELECTION BLOCK', name, values)

    firstUpdate.current = false

    const selectedDaysCount = Object.values(values).filter(bool => bool).length

    if (selectedDaysCount > 0 && selectedDaysCount < 5) {
      setSelection(`partTime_${name}`)
      // onChange(`partTime_${name}`, true, false)
    } else if (selectedDaysCount === 5) {
      setSelection(`fullTime_${name}`)
      // onChange(`partTime_${name}`, false, false)
    }
  }, [values, name, onChange])

  const handleClick = ({ target }) => {
    // Note - calling onChange will trigger a rerender
    console.log("Clicked on", target.id, target.value)

    // If we're unchecking, reset all days to false
    if (target.id === selection) {
      setSelection(null)

      // formikProps.setValues({
      //   ...formikProps.values,
      //   [name]: { mo: false, tu: false, we: false, th: false, fr: false },
      //   [`partTime_${name}`]: false,
      // })

      // formikProps.setValues(fuckMe)
      onChange(name, { mo: false, tu: false, we: false, th: false, fr: false })
      // onChange(`partTime_${name}`, false, true) // rerun validation check
    } else {
      // If we checked full time, set all days to true
      if (target.id === `fullTime_${name}`) {
        // partTimeCheck.current.checked = false
        // formikProps.setValues({
        //   ...values,
        //   [name]: { mo: true, tu: true, we: true, th: true, fr: true },
        //   [`partTime_${name}`]: false,
        // })
        onChange(
          name,
          { mo: true, tu: true, we: true, th: true, fr: true },
          true
        )
        // onChange(`partTime_${name}`, false, true) // rerun validation check
      }
      // If we checked part time, set all days to false as a reset
      if (target.id === `partTime_${name}`) {
        // fullTimeCheck.current.checked = false
        // formikProps.setValues({
        //   ...values,
        //   [name]: { mo: false, tu: false, we: false, th: false, fr: false },
        //   [`partTime_${name}`]: true,
        // })
        onChange(
          name,
          { mo: false, tu: false, we: false, th: false, fr: false },
          false
        )
        // onChange(`partTime_${name}`, true, false) // don't run validation check
      }
      setSelection(target.id)
    }
  }

  /**
   * Takes the daySelection state and decides what to do with changes
   * Called on each click of a part time day checkbox
   */
  const handleDayClick = ({ target }) => {
    // console.log('Clicked on a day', target.id, target.name, target.checked)
    const newState = { ...values }

    newState[target.name] = target.checked
    onChange(name, newState)
  }

  return (
    <>
      <div tw="flex items-center h-full my-4 md:my-0 w-full md:w-1/3">
        <input
          type="checkbox"
          id={`fullTime_${name}`}
          name={`fullTime_${name}`}
          css={styledCheckbox}
          value="Full-time"
          checked={selection === `fullTime_${name}`}
          onChange={handleClick}
        />

        <label css={label} tw="mb-0 ml-2" htmlFor={`fullTime_${name}`}>
          Full-time{" "}
          <span tw="font-normal whitespace-nowrap block">( 5 days/week )</span>
        </label>
      </div>

      <div tw="flex items-center mb-4 md:ml-6 md:my-0 h-full w-auto">
        <input
          type="checkbox"
          id={`partTime_${name}`}
          name={`partTime_${name}`}
          css={styledCheckbox}
          value="Part-time"
          checked={selection === `partTime_${name}`}
          onChange={handleClick}
        />
        {selection !== `partTime_${name}` ? (
          <label css={label} tw="mb-0 ml-2" htmlFor={`partTime_${name}`}>
            Part-time{" "}
            <span tw="font-normal whitespace-nowrap block">
              ( select days of the week )
            </span>
          </label>
        ) : (
          <div tw="ml-2 flex">
            {Object.keys(days).map((day, i) => (
              <div tw="flex flex-col items-center" key={i}>
                <label
                  tw="text-xs font-bold py-2 px-4 bg-gray-100 border-b border-purple-lightest capitalize"
                  htmlFor={day}
                >
                  {day}
                </label>
                <input
                  type="checkbox"
                  id={day}
                  name={day}
                  onChange={handleDayClick}
                  css={styledCheckbox}
                  checked={values[day]}
                  tw="my-2"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default WeekAttendanceChoice
